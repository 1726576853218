<template>
  <div>
    <!-- <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template> -->
    <!-- Lotto THAI -->
    <div
      v-if="payOut"
      class="step-2"
    >
      <div class="action-bar d-flex align-items-center justify-content-between bar-white">
        <div class="nav-left">
          <div class="nav-text pd-h-20">
            รายการแทง {{ countArry }} รายการ
          </div>
        </div>
        <div class="nav-right">
          <a
            class="link-close"
            @click="payOut = !payOut"
          ><i class="fas fa-times fa-2x" /></a>
        </div>
      </div>
      <div
        v-if="confirm == false"
        class="container c-690 special-pd-bottom"
      >
        <template>
          <validation-observer ref="BetItemsRules">
            <b-table
              small
              responsive
              head-variant="light"
              :fields="fields"
              :items="items"
              class="mb-4 text-center"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width: field.key === 'foo' ? '120px' : '180px',
                  }"
                >
              </template>
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(price)="data">
                <validation-provider
                  #default="{ errors }"
                  :rules="`required|between:${
                    CheckAmount(data.item.num_type).Min
                  },${CheckAmount(data.item.num_type).Max}`"
                  name="Number between"
                >
                  <b-form-input
                    v-model="data.item.price"
                    type="number"
                    class="form-css text-center"
                    placeholder=""
                    @change="
                      CheckAmount(
                        data.item.num_type,
                        data.item.price,
                        data.index,
                        data.item.num_lot,
                      )
                    "
                  />
                  <!-- @change="CheckAmount(data.item.num_type,data.item.price,data.index)" -->
                  <small class="text-danger">{{
                    errors[0] ||
                      TestPrice(
                        data.item.price,
                        CheckAmount(data.item.num_type).Min,
                        CheckAmount(data.item.num_type).Max,
                      ) === true
                      ? `ระบุจำนวนเงิน ${Commas(
                        CheckAmount(data.item.num_type).Min,
                      )}-${Commas(
                        CheckAmount(data.item.num_type).Max,
                      )} บาท`
                      : ''
                  }}</small>
                </validation-provider>
              </template>

              <template #cell(win_rate)="data">
                <p
                  :style="`color: ${
                    data.item.RateLimit ? 'red' : 'black'
                  }`"
                  class="mt-1"
                >
                  {{ data.item.win_rate }}
                </p>
              </template>

              <template #cell(win)="data">
                {{ Commas(data.item.win_rate * data.item.price) }}
              </template>

              <template #cell(delete)="data">
                <!-- {{ data.index }} -->
                <b-button
                  variant="danger"
                  size="sm"
                  class="btn-icon"
                  style="height: 32px; width: 32px;"
                  @click="items.splice(data.index, 1)"
                >
                  <i class="fas fa-trash-alt" />
                </b-button>
              </template>
            </b-table>
          </validation-observer>
        </template>
      </div>
      <div
        v-if="confirm == true"
        class="container c-690 special-pd-bottom"
      >
        <template>
          <validation-observer ref="BetItemsRules">
            <b-table
              responsive
              head-variant="light"
              :fields="fieldss"
              :items="items"
              class="mb-4 text-center"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fieldss"
                  :key="field.key"
                  :style="{
                    width: field.key === 'foo' ? '120px' : '180px',
                  }"
                >
              </template>
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(price)="data">
                <validation-provider
                  #default="{ errors }"
                  :rules="`required|between:${
                    CheckAmount(data.item.num_type).Min
                  },${CheckAmount(data.item.num_type).Max}`"
                  name="Number between"
                >
                  <b-form-input
                    v-model="data.item.price"
                    disabled
                    type="number"
                    class="form-css text-center"
                    placeholder=""
                    @change="
                      CheckAmount(
                        data.item.num_type,
                        data.item.price,
                        data.index,
                        data.item.num_lot,
                      )
                    "
                  />
                  <!-- @change="CheckAmount(data.item.num_type,data.item.price,data.index)" -->
                  <small class="text-danger">{{
                    errors[0] ||
                      TestPrice(
                        data.item.price,
                        CheckAmount(data.item.num_type).Min,
                        CheckAmount(data.item.num_type).Max,
                      ) === true
                      ? `ระบุจำนวนเงิน ${Commas(
                        CheckAmount(data.item.num_type).Min,
                      )}-${Commas(
                        CheckAmount(data.item.num_type).Max,
                      )} บาท`
                      : ''
                  }}</small>
                </validation-provider>
              </template>

              <template #cell(win_rate)="data">
                <p
                  :style="`color: ${
                    data.item.RateLimit ? 'red' : 'black'
                  }`"
                  class="mt-1"
                >
                  {{ data.item.win_rate }}
                </p>
              </template>

              <template #cell(win)="data">
                {{ Commas(data.item.win_rate * data.item.price) }}
              </template>
            </b-table>
          </validation-observer>
        </template>
      </div>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <div class="row-bottom-action bet-action-bottom">
        <div class="bet-detail">
          <div class="font-sub-title color-sub">
            เงินคงเหลือ
          </div> <div class="font-page-title color-white credit-value">
            {{
              Commas(this.$store.state.appConfig.UserData.MainWallet)
            }} ฿
          </div>
        </div> <div class="bet-detail">
          <div class="font-sub-title color-sub text-right">
            ยอดเดิมพันทั้งหมด
          </div> <div class="font-page-title color-white text-right">
            {{ Commas(CalSumAmount()) }} ฿
          </div>

        </div> <div class="clearfix" />
        <div
          v-if="confirm == false"
          class="bet-form mr-auto"
        >
          <div class="row">
            <div class="col-6">
              <div class="font-sub-title color-sub">
                เดิมพันราคาเท่ากัน
              </div>
            </div>
            <div class="col-6">
              <p
                v-if="AgentData.discount > 0"
                class="font-sub-title color-sub text-right"
              >
                ส่วนลด {{ AgentData.discount }} %
              </p>
            </div>
          </div>

          <div class="form-group">
            <input
              v-model="pricebase"
              type="number"
              value="5"
              class="input-bottom-bet float-left"
              style="width: 100%;"
              @change="AddPriceBase(pricebase)"
            > <div class="clearfix" />
          </div> <div class="container">
            <div class="row gutters-10">
              <div
                :disabled="CheckSubmit === true"
                class="col"
                @click="AddPriceBase(5,pricebase = 5)"
              >
                <a class="bet-sm-item">5</a>
              </div> <div
                :disabled="CheckSubmit === true"
                class="col"
                @click="AddPriceBase(10,pricebase = 10)"
              >
                <a class="bet-sm-item">10</a>
              </div> <div
                :disabled="CheckSubmit === true"
                class="col"
                @click="AddPriceBase(20,pricebase = 20)"
              >
                <a class="bet-sm-item">20</a>
              </div> <div
                :disabled="CheckSubmit === true"
                class="col"
                @click="AddPriceBase(50, pricebase = 50)"
              >
                <a class="bet-sm-item">50</a>
              </div> <div
                :disabled="CheckSubmit === true"
                class="col"
                @click="AddPriceBase(100, pricebase = 100)"
              >
                <a class="bet-sm-item">100</a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="confirm == true"
          class="bet-form mr-auto"
        />
        <div class="bet-button">
          <button
            :disabled="confirm == true"
            type="button"
            class="btn btn-outline-light btn-bet-s"
            @click="ClearPriceBase()"
          >
            ล้างข้อมูล
          </button>
          <button
            v-if="confirm == false"
            type="button"
            class="btn btn-bet-l"
            style="background: #FE7C86 !important; color: #fff !important"
            @click="confirm = true"
          >
            แทงพนัน
          </button>
          <button
            v-if="confirm == true"
            type="button"
            class="btn btn-bet-l"
            style="background: #FE7C86 !important; color: #fff !important"
            @click="SummitBet(),payOut = !payOut"
          >
            ส่งโพย
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row-top-action">
        <div>
          <b-row class="align-items-center">
            <b-col
              lg="6"
              md="6"
              cols="6"
              class="d-flex align-items-center py-1"
            >
              <router-link :to="{ name: 'lottery' }">
                <i class="fas fa-arrow-left fa-lg mr-1 text-white" />
              </router-link>
              <h4
                class="align-items-center topic text-white"
              >
                {{ Title.LottoName }}
              </h4></b-col>
            <b-col
              lg="6"
              md="6"
              cols="6"
              class="py-1"
            >
              <div class="d-flex justify-content-end align-items-center">
                <button
                  class="view-rules"
                  @click="showRule(Title.LottoName)"
                ><i class="fas fa-exclamation-circle" />&nbsp;กติกา</button>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="text-center py-1"
              style="border-top: 1px solid #000"
            >
              <strong class="text-white">
                ปิดรับ <i class="far fa-clock" /> {{ Title.ShowCloseDate }}
              </strong>
            </b-col>
          </b-row>

          <b-modal
            ref="my-rule"
            hide-footer
            title="กติกา"
          >
            <div>
              <t-h-g v-if="rule === 'หวยรัฐบาล'" />
              <g-s-b v-if="rule === 'หวยออมสิน'" />
              <t-k-s v-if="rule === 'หวย ธกส'" />
              <t-h-z v-if="rule === 'หวยหุ้นไทย'" />
              <y-g v-if="rule === 'หวยยี่กี'" />
              <d-e v-if="rule === 'เยอรมัน'" />
              <e-g v-if="rule === 'อียิปต์'" />
              <g-b v-if="rule === 'อังกฤษ'" />
              <l-a v-if="rule === 'ลาว'" />
              <j-p v-if="rule === 'หุ้นนิเคอิ'" />
              <s-g v-if="rule === 'สิงคโปร์'" />
              <t-w v-if="rule === 'ไต้หวัน'" />
              <c-n v-if="rule === 'จีน'" />
              <i-n v-if="rule === 'อินเดีย'" />
              <r-u v-if="rule === 'รัสเซีย'" />
              <v-n v-if="rule === 'ฮานอย'" />
              <h-k v-if="rule === 'ฮั่งเส็ง'" />
              <m-l v-if="rule === 'มาเลย์'" />
              <k-r v-if="rule === 'หุ้นเกาหลี'" />
            </div>
          </b-modal>
        </div>
      </div>

      <!-- ตัวเลือกหวย -->
      <div class="mb-1 pdd">
        <b-row class="header-bg-content2">
          <b-col
            v-if="
              Title.LottoDetail.u3 ||
                Title.LottoDetail.d3 ||
                Title.LottoDetail.t3
            "
            lg="4"
            md="4"
            cols="4"
            class="p-0"
          >
            <div>
              <button
                :class="`btn-type3 btnp ${ShowDigit === 3 ? 'active' : ''}`"
                @click="SelectDigit(3)"
              >
                หวย 3 ตัว
              </button>
            </div>
          </b-col>
          <b-col
            v-if="
              Title.LottoDetail.u2 ||
                Title.LottoDetail.d2 ||
                Title.LottoDetail.t2
            "
            lg="4"
            md="4"
            cols="4"
            class="p-0"
          >
            <div>
              <button
                :class="`btn-type2 btnp ${ShowDigit === 2 ? 'active' : ''}`"
                @click="SelectDigit(2)"
              >
                หวย 2 ตัว
              </button>
            </div>
          </b-col>
          <b-col
            v-if="Title.LottoDetail.u1 || Title.LottoDetail.d1"
            lg="4"
            md="4"
            cols="4"
            class="p-0"
          >
            <div>
              <button
                :class="`btn-type1 btnp ${ShowDigit === 1 ? 'active' : ''}`"
                @click="SelectDigit(1)"
              >
                เลขวิ่ง
              </button>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="row match-height pdd">
        <div class="col-12">
          <strong>เลือกประเภทการซื้อ</strong>
          <!-- 3 ตัว -->
          <div
            v-if="ShowDigit === 3"
            class="row"
          >
            <div
              v-if="Title.LottoDetail.u3"
              class="col-4"
            >
              <button
                class="align-items-center"
                :class="`bet-list-item yellow ${Clicked === 1 ? 'active' : ''}`"
                @click="SelectBetType3('3ตัวบน')"
              ><i class="fas fa-plus-circle" />
                สามตัวบน
              </button>
            </div>

            <div
              v-if="Title.LottoDetail.u3"
              class="col-4"
            >
              <button
                class="bet-list-item yellow"
                @click="SelectBetType3('3ตัวโต๊ด')"
              ><i class="fas fa-plus-circle" />
                สามตัวโต๊ด
              </button>
            </div>

            <div
              v-if="Title.LottoDetail.u3"
              class="col-4"
            >
              <button
                class="bet-list-item yellow"
                @click="SelectBetType3('กลับตัวเลข')"
              ><i class="fas fa-plus-circle" />
                สามตัวกลับ
              </button>
            </div>
          </div>
          <!-- <div
            v-if="Title.LottoDetail.d3"
            class="col-6 col-sm-6 col-md-4 col-lg-3"
          >
            <b-button
              variant="outline-danger"
              class="btn-outline-red btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType3('3ตัวล่าง')"
            >
              <div class="bg-danger text-white">
                3 ตัวล่าง
              </div>
              <div>{{ RateAll.Three.Down.Rate }}</div>
            </b-button>
          </div> -->
          <!-- <div
            v-if="Title.LottoDetail.t3"
            class="col-6 col-sm-6 col-md-4 col-lg-3"
          >
            <b-button
              variant="outline-danger"
              class="btn-outline-red btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType3('3ตัวโต๊ด')"
            >
              <div class="bg-danger text-white">
                3 ตัวโต๊ด
              </div>
              <div>{{ RateAll.Three.Tot.Rate }}</div>
            </b-button>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3">
            <b-button
              variant="outline-danger"
              class="btn-outline-red btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType3('กลับตัวเลข')"
            >
              <div class="bg-danger text-white">
                3 ตัวกลับ
              </div>
              <div class="text-center">
                <i
                  aria-hidden="true"
                  class="fas fa-random"
                />
              </div>
            </b-button>
          </div>
        </div> -->

          <!-- 2 ตัว -->
          <div
            v-if="ShowDigit === 2"
            class="row row-btn-tanghuay setting__number"
          >
            <div
              v-if="Title.LottoDetail.u2"
              class="col-4 col-sm-4 col-md-4 col-lg-4"
            >
              <button
                class="bet-list-item orange"
                @click="SelectBetType2('2ตัวบน')"
              ><i class="fas fa-plus-circle" />
                สองตัวบน
              </button>
            </div>

            <div
              v-if="Title.LottoDetail.d2"
              class="col-4 col-sm-4 col-md-4 col-lg-4"
            >
              <button
                class="bet-list-item orange"
                @click="SelectBetType2('2ตัวล่าง')"
              ><i class="fas fa-plus-circle" />
                สองตัวล่าง
              </button>

            </div>
            <div
              class="col-4 col-sm-4 col-md-4 col-lg-4"
            >
              <button
                class="bet-list-item orange"
                @click="SelectBetType2('กลับตัวเลข')"
              ><i class="fas fa-plus-circle" />
                สองตัวกลับ
              </button>

            </div>

            <div
              v-if="
                BetType.indexOf('2ตัวบน') >= 0 ||
                  BetType.indexOf('2ตัวล่าง') >= 0
              "
              class="col-12 col-sm-12 col-md-12 col-lg-12 bg-option p-1 pb-0 rounded box__two-option box__play setting__number"
            >
              <i
                aria-hidden="true"
                class="fas fa-bars"
              /> ตัวเลือกเพิ่มเติม
              <div
                class="d-flex flex-lg-row justify-content-around align-content-stretch flex-wrap"
              >
                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-danger btn-sm btn-block h-100 bet_two option2btn"
                      @click="SelectBetType2('19ประตู')"
                    >
                      19 ประตู
                    </button>
                  </div>
                </div>
                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-primary btn-sm btn-block h-100 bet_two option2btn"
                      @click="SelectBetType2('รูดหน้า')"
                    >
                      รูดหน้า
                    </button>
                  </div>
                </div>
                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-primary btn-sm btn-block h-100 bet_two option2btn"
                      @click="SelectBetType2('รูดหลัง')"
                    >
                      รูดหลัง
                    </button>
                  </div>
                </div>

                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-info btn-sm btn-block h-100 bet_two"
                      @click="SelectBetType2('สองตัวต่ำ')"
                    >
                      สองตัวต่ำ
                    </button>
                  </div>
                </div>

                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-info btn-sm btn-block h-100 bet_two"
                      @click="SelectBetType2('สองตัวสูง')"
                    >
                      สองตัวสูง
                    </button>
                  </div>
                </div>

                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-success btn-sm btn-block h-100 bet_two"
                      @click="SelectBetType2('สองตัวคี่')"
                    >
                      สองตัวคี่
                    </button>
                  </div>
                </div>

                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-success btn-sm btn-block h-100 bet_two"
                      @click="SelectBetType2('สองตัวคู่')"
                    >
                      สองตัวคู่
                    </button>
                  </div>
                </div>

                <div class="flex-fill">
                  <div class="bg-btn">
                    <button
                      class="btn btn-outline-success btn-sm btn-block h-100 bet_two"
                      @click="SelectBetType2('กลับตัวเลข')"
                    >
                      กลับตัวเลข
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 1 ตัว -->
          <div
            v-if="ShowDigit === 1"
            class="row row-btn-tanghuay setting__number"
          >
            <div
              v-if="Title.LottoDetail.u1"
              class="col-6 col-sm-6 col-md-4 col-lg-3"
            >
              <button
                class="bet-list-item green"
                @click="SelectBetType1('วิ่งบน')"
              ><i class="fas fa-plus-circle" />
                วิ่งบน
              </button>
            </div>

            <div
              v-if="Title.LottoDetail.d1"
              class="col-6 col-sm-6 col-md-4 col-lg-3"
            >
              <button
                class="bet-list-item green"
                @click="SelectBetType1('วิ่งล่าง')"
              ><i class="fas fa-plus-circle" />
                วิ่งล่าง
              </button>

            </div>
          </div>

          <!-- แทงตัวเลข -->
          <div>
            <div class="key-pad box__keyboard">
              <!-- <div
              v-if="
                BetType.indexOf('3ตัวบน') >= 0 ||
                  BetType.indexOf('3ตัวล่าง') >= 0 ||
                  BetType.indexOf('3ตัวโต๊ด') >= 0 ||
                  BetType.indexOf('2ตัวบน') >= 0 ||
                  BetType.indexOf('2ตัวล่าง') >= 0 ||
                  BetType.indexOf('2ตัวโต๊ด(บน)') >= 0 ||
                  BetType.indexOf('วิ่งบน') >= 0 ||
                  BetType.indexOf('วิ่งล่าง') >= 0
              "
            > -->
              <div>

                <div class="key-pad box__keyboard">
                  <h6
                    v-if="!CheckBetType"
                    class="text-center"
                  >
                    ระบุตัวเลข {{ CalMaxLength() }} ตัว
                  </h6>
                  <h6
                    v-else
                    class="text-center"
                  >
                    กรุณาเลือกประเภท
                  </h6>
                  <div class="text-center mt-1">
                    <otp-input
                      v-if="BugOtp === true"
                      v-model="NumBet"
                      class="style-1"
                      :length="CalMaxLength()"
                      pattern="[^0-9]+"
                      :ignore-pattern="false"
                      :size="32"
                      :disabled="CheckBetType"
                      @valid="handleOnComplete()"
                    />
                  </div>

                  <div
                    v-if="BetType.length > 0"
                    class="text-center"
                  >
                    <b-badge
                      v-for="item in BetType"
                      :key="`BetType${item}`"
                      :variant="
                        item === 'กลับตัวเลข' ||
                          item === 'รูดหน้า' ||
                          item === 'รูดหลัง'
                          ? 'dark'
                          : ShowDigit === 3
                            ? 'danger'
                            : ShowDigit === 2
                              ? 'info'
                              : 'success'
                      "
                      style="margin-right: 0.3rem"
                    >
                      {{ item }}
                    </b-badge>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 mmb-1">
                    <button
                      data-id="1"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(1)"
                    >
                      1
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="2"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(2)"
                    >
                      2
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="3"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(3)"
                    >
                      3
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="4"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(4)"
                    >
                      4
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="5"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(5)"
                    >
                      5
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="6"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(6)"
                    >
                      6
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="7"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(7)"
                    >
                      7
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="8"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(8)"
                    >
                      8
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="9"
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(9)"
                    >
                      9
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <!-- @click="NumBet = ''" -->
                    <button
                      v-b-modal.modal-1
                      class="button-num-cals"
                      @click="Undo()"
                    >
                      ล้าง
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      class="button-num-cal push-btn-effect"
                      :disabled="CheckBetType"
                      @click="Betting(0)"
                    >
                      0
                    </button>
                  </div>
                  <div class="col-4 mmb-1">
                    <button
                      data-id="delete"
                      class="button-num-calss"
                      @click="NumBet = NumBet.slice(0, -1)"
                    >
                      <i
                        aria-hidden="true"
                        class="fas fa-backspace"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>

          <!-- เงื่อนไขการแทง -->
          <div>
            <div class="section-wrap">
              <div class="section-title-l mb-20">
                <strong class="font-heading">เงื่อนไขการแทง</strong>
              </div> <div class="section-content">
                <div class="row">
                  <div
                    v-if="ShowDigit === 3"
                    class="col-6 col-md-6"
                  >
                    <strong>สามตัวบน</strong> <ul class="list-play-detail">
                      <li><div class="float-left">
                        ราคาจ่าย <!---->
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Three.Up.Rate }} <!----></strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงขั้นต่ำต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ Commas(RateAll.Three.Up.Min) }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ Commas(RateAll.Three.Up.Max) }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อเลข
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ Commas(RateAll.Three.Up.BetLimit) }}</strong>
                      </div> <div class="clearfix" /></li>
                    </ul>
                  </div>
                  <div
                    v-if="ShowDigit === 3"
                    class="col-6 col-md-6"
                  >
                    <strong>สามตัวโต๊ด</strong> <ul class="list-play-detail">
                      <li><div class="float-left">
                        ราคาจ่าย <!---->
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Three.Tot.Rate }} <!----></strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงขั้นต่ำต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ Commas(RateAll.Three.Tot.Min) }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ Commas(RateAll.Three.Tot.Max) }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อเลข
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ Commas(RateAll.Three.Tot.BetLimit) }}</strong>
                      </div> <div class="clearfix" /></li>
                    </ul>
                  </div>
                  <div
                    v-if="ShowDigit === 2"
                    class="col-6 col-md-6"
                  >
                    <strong>สองตัวบน</strong> <ul class="list-play-detail">
                      <li><div class="float-left">
                        ราคาจ่าย <!---->
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Up.Rate }} <!----></strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงขั้นต่ำต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Up.Min }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Up.Max }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อเลข
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Up.BetLimit }}</strong>
                      </div> <div class="clearfix" /></li>
                    </ul>
                  </div>
                  <div
                    v-if="ShowDigit === 2"
                    class="col-6 col-md-6"
                  >
                    <strong>สองตัวล่าง</strong> <ul class="list-play-detail">
                      <li><div class="float-left">
                        ราคาจ่าย <!---->
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Down.Rate }} <!----></strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงขั้นต่ำต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Down.Min }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Down.Max }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อเลข
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.Two.Down.BetLimit }}</strong>
                      </div> <div class="clearfix" /></li>
                    </ul>
                  </div>
                  <div
                    v-if="ShowDigit === 1"
                    class="col-6 col-md-6"
                  >
                    <strong>วิ่งบน</strong> <ul class="list-play-detail">
                      <li><div class="float-left">
                        ราคาจ่าย <!---->
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Up.Rate }} <!----></strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงขั้นต่ำต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Up.Min }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Up.Max }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อเลข
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Up.BetLimit }}</strong>
                      </div> <div class="clearfix" /></li>
                    </ul>
                  </div>
                  <div
                    v-if="ShowDigit === 1"
                    class="col-6 col-md-6"
                  >
                    <strong>วิ่งล่าง</strong> <ul class="list-play-detail">
                      <li><div class="float-left">
                        ราคาจ่าย <!---->
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Down.Rate }} <!----></strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงขั้นต่ำต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Down.Min }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อครั้ง
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Down.Max }}</strong>
                      </div> <div class="clearfix" /></li> <li><div class="float-left">
                        แทงสูงสุดต่อเลข
                      </div> <div class="float-right">
                        <strong class="color-orange">{{ RateAll.One.Down.BetLimit }}</strong>
                      </div> <div class="clearfix" /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
        <!-- </b-overlay> -->
      </div>

      <div class="row-bottom-action">
        <a
          href="#"
          class="nav-go-top"
        >
          <i class="fas fa-arrow-up fa-2x text-white mr-1 ml-1" />
        </a>
        <div
          class="nav-text mr-auto"
          style="cursor: pointer;"
        >
          <div class="font-sub-title text-white">
            รายการแทง
          </div>
          <div
            class="font-page-title text-white"
          >
            {{ countArry }} รายการ
          </div>
        </div>
        <!-- v-b-toggle.sidebar-footer -->
        <button
          class="btn p-0"
          style="background: #FE7C86 !important; color: #fff; font-weight: 700;"
          @click="payOut = !payOut"
        >
          <i class="fal fa-cart-plus" /> ใส่ราคาแทง
        </button>
      </div>
    </div>
    <b-modal
      ref="my-modal"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="text-center mt-2 mb-2">
        <b-avatar
          variant="light-success"
          size="80"
        >
          <i class="fas fa-thumbs-up fa-3x" />
        </b-avatar>

        <h3 class="font-weight-bolder text-success mt-1">
          ระบบได้ทำการส่งโพยของท่านให้เรียบร้อยแล้ว
        </h3>
      </div>
      <table
        style="width: 100%"
        class="pl-1 pr-1 mb-1"
      >
        <tbody>
          <tr>
            <td
              colspan="2"
              class="text-center"
            >
              <h4 class="font-weight-bolder">
                {{ Slip.LottoHeadName }}
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <small>วันที่</small>
              <h4 class="font-weight-bolder">
                {{ Slip.date }}
              </h4>
            </td>
            <td>
              <small>เวลา</small>
              <h4 class="font-weight-bolder">
                {{ Slip.time }} น.
              </h4>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <small>โพยเลขที่</small>
              <h4 class="font-weight-bolder">
                #{{ Slip.listID }}
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <small>รายการแทง</small>
              <h4 class="font-weight-bolder">
                {{ Slip.count }} รายการ
              </h4>
            </td>
            <td>
              <small>ยอดเดิมพันทั้งหมด</small>
              <h4 class="font-weight-bolder">
                {{ Slip.SumAmount }} บาท
              </h4>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col-6">
          <router-link :to="{path : `/list-huay/${Slip.listID}`}">
            <b-button
              variant="danger"
              block
            >
              ดูโพย
            </b-button>
          </router-link>
        </div>

        <div class="col-6">
          <b-button
            variant="warning"
            block
            style="color: #000 !important"
            @click="hideModal"
          >
            แทงหวยต่อ
          </b-button>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, between, digits } from '@validations'
import {
  BButton,
  BRow,
  BCol,
  BModal,
  BTable,
  BFormInput,
  // BCard,
  BBadge,
  BAvatar,
  VBToggle,
  // BOverlay,
  // BIconController,
} from 'bootstrap-vue'

// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import HomeBanner from './HomeBanner.vue'
// import HomeSponsor from './HomeSponsor.vue'
import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import OtpInput from '@8bu/vue-otp-input'
import ML from './rule/ml.vue'
import THG from './rule/thg.vue'
import GSB from './rule/gsb.vue'
import TKS from './rule/tks.vue'
import YG from './rule/yg.vue'
import DE from './rule/de.vue'
import GB from './rule/gb.vue'
import LA from './rule/la.vue'
import JP from './rule/jp.vue'
import SG from './rule/sg.vue'
import TW from './rule/tw.vue'
import CN from './rule/cn.vue'
import IN from './rule/in.vue'
import RU from './rule/ru.vue'
import VN from './rule/vn.vue'
import EG from './rule/eg.vue'
import HK from './rule/hk.vue'
import THZ from './rule/thz.vue'
import KR from '../rule/kr.vue'

// import SidebarContent from './SidebarContent.vue'
// import SidebarContentFooter from './SidebarContentFooter.vue'

export default {
  components: {
    BButton,
    // HomeBanner,
    // HomeSponsor,
    BRow,
    BCol,
    OtpInput,
    BModal,
    BTable,
    BFormInput,
    // BCard,
    BBadge,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    ML,
    THG,
    TKS,
    YG,
    EG,
    DE,
    GB,
    LA,
    SG,
    TW,
    CN,
    JP,
    IN,
    RU,
    VN,
    GSB,
    HK,
    THZ,
    KR,
    // SidebarContentFooter,
    // BOverlay,
    // BIconController,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      rule: null,
      confirm: false,
      countArry: 0,
      required,
      between,
      digits,
      payOut: false,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      ShowLimit: false,
      ShowClose: false,
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
      ],
      // LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoList: [
        { text: 'กรุณาเลือกหวย', value: null, SubList: null },
        { text: 'หวยรัฐบาล', value: 'THG', SubList: null },
        {
          text: 'ธกส',
          value: 'BAAC',
          SubList: [
            { text: 'หวย ธกส.(BAAC1)', value: 'BAAC1' },
            { text: 'หวย ธกส.(BAAC2)', value: 'BAAC2' },
          ],
        },
      ],
      SumAmount: 0,
      AmountBet: 1,
      NumBet: '',
      otp: '',
      Status: 'Diposit',
      Clicked: 2,
      ShowDigit: 3,
      CheckBetType: true,
      maxlength: 3,
      // BetType: ['3ตัวบน', 'กลับตัวเลข'],
      BetType: [],
      Interval: null,
      IntervalRate: null,
      AgentData: null,
      fields: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
        { key: 'delete', label: 'ลบ', thStyle: { width: '5%' } },
      ],
      fieldss: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
      ],
      items: [],
      Historyitems: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      MinAmount: 0,
      MaxAmount: 0,
      Title: {
        RoundID: '',
        LottoName: '',
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
      Slip: {
        SumAmount: null,
        date: null,
        time: null,
        listID: null,
        count: null,
        LottoHeadName: null,
      },
    }
  },

  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalRate)
  },
  async mounted() {
    const Type = JSON.parse(atob(this.$route.params.Type))
    this.Title.LottoHead = Type.LottoHead
    this.Title.LottoSubHead = Type.LottoSubHead
    this.show = true
    this.ShowAgent()
    this.LottoList = await this.GetSublist()
    await this.ShowTitle()
    await this.GetMaxMin()
    await this.GetRate(this.Title.RoundID)
    this.show = false
    this.Interval = setInterval(() => {
      if (this.Title.CloseDate) {
        this.Title.ShowCloseDate = this.CalDiffTime(this.Title.CloseDate)
      }
    }, 1000)
    this.IntervalRate = setInterval(() => {
      this.GetRate(this.Title.RoundID)
    }, 60000)
    this.Interval = setInterval(() => {
      this.ShowTitle()
      this.GetMaxMin()
    }, 300000)
  },
  methods: {
    showRule(data) {
      if (data === 'หวยรัฐบาล') {
        this.rule = 'หวยรัฐบาล'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยออมสิน') {
        this.rule = 'หวยออมสิน'
        this.$refs['my-rule'].show()
      } else if (data === 'หวย ธกส') {
        this.rule = 'หวย ธกส'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เช้า)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เที่ยง)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (บ่าย)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เย็น)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'เยอรมัน') {
        this.rule = 'เยอรมัน'
        this.$refs['my-rule'].show()
      } else if (data === 'อังกฤษ') {
        this.rule = 'อังกฤษ'
        this.$refs['my-rule'].show()
      } else if (data === 'ลาว') {
        this.rule = 'ลาว'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (เช้า)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (บ่าย)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'สิงคโปร์') {
        this.rule = 'สิงคโปร์'
        this.$refs['my-rule'].show()
      } else if (data === 'ไต้หวัน') {
        this.rule = 'ไต้หวัน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (เช้า)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (บ่าย)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === 'อินเดีย') {
        this.rule = 'อินเดีย'
        this.$refs['my-rule'].show()
      } else if (data === 'รัสเซีย') {
        this.rule = 'รัสเซีย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮานอย') {
        this.rule = 'ฮานอย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (เช้า)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (บ่าย)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'มาเลย์') {
        this.rule = 'มาเลย์'
        this.$refs['my-rule'].show()
      } else if (data === 'อียิปต์') {
        this.rule = 'อียิปต์'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยยี่กี') {
        this.rule = 'หวยยี่กี'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นเกาหลี') {
        this.rule = 'หุ้นเกาหลี'
        this.$refs['my-rule'].show()
      }
    },
    async ShowAgent() {
      try {
        const { data: response } = await this.$http.get(
          'https://webapi.linelotto.vip/api/agent/show',
        )
        if (response && response.success === true) {
          this.AgentData = response.AgentData
        }
      } catch (err) {
        console.log(err)
      }
    },
    ClearPriceBase() {
      this.confirm = false
      this.pricebase = null
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.countArry - 1; i++) {
        this.items[i].price = 1
      }
    },
    AddPriceBase(val) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.countArry - 1; i++) {
        this.items[i].price = val
      }
    },
    provide(item) {
      if (this.candidates.indexOf(item) === -1) {
        this.candidates.push(item)
      } else {
        this.candidates.splice(this.candidates.indexOf(item), 1)
      }
    },
    contains(arr, item) {
      return arr.indexOf(item) !== -1
    },
    btnStates() {
      return this.buttons.map(btn => btn.state)
    },
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('https://webapi.linelotto.vip/api/LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
    ShowGroupList(Key) {
      if (this.items.length > 0) {
        const grouped = this.groupBy(this.items, group => group.num_type)
        return grouped.get(Key)
      }
      return null
    },
    handleOnComplete() {
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 100)
      }
    },
    Betting(val) {
      if (this.NumBet.length < this.CalMaxLength()) {
        this.NumBet += val
      }
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 200)
        // console.log(this.items)
      }
    },
    showAlert(CloseBet) {
      this.CloseBetModal = CloseBet
      this.$refs['modal-alert'].show()
    },
    showModal(data) {
      // console.log(data)
      this.Slip.SumAmount = data.Bet.reduce(
        (acc, ele) => acc + Number(ele.amount),
        0,
      )
      this.Slip.date = momenttz(data.Bet[0].created_at).format('DD/MM/YYYY')
      this.Slip.time = momenttz(data.Bet[0].created_at).format('HH:mm:ss')
      this.Slip.count = data.Bet.length
      this.Slip.listID = data.Bet[0].list_id
      this.Slip.LottoHeadName = data.Bet[0].LottoHeadName
      // console.log(this.Slip)
      this.$refs['my-modal'].show()
    },
    async SummitBet() {
      const CheckPrice = this.items.some(el => el.price === '')
      this.$refs.BetItemsRules.validate().then(async success => {
        if (success && CheckPrice === false) {
          this.CheckSubmit = false
          if (
            this.SumAmount > this.$store.state.appConfig.UserData.MainWallet
          ) {
            this.SwalMes(
              'error',
              'จำนวนเงินไม่ถูกต้อง !',
              'ยอดเงินของคุณไม่เพียงพอกรุณาลองใหม่อีกครั้งค่ะ',
            )
            // this.items.splice(this.items.length - 1, 1)
          } else {
            this.show = true
            const Obj = {
              SumAmount: this.SumAmount,
              discount: this.AgentData.discount,
              OBJ: this.items,
            }
            try {
              // const { data: response } = await this.$http.post(
              //   'http://127.0.0.1:8888/api/bet/store',
              //   Obj,
              // )
              const { data: response } = await this.$http.post(
                'https://webapi.linelotto.vip/api/bet/store',
                Obj,
              )
              if (response) {
                this.ShowWallet()
                this.ShowTitle()
                this.GetMaxMin()
                this.GetRate(this.Title.RoundID)
                this.show = false
                this.items = []
                this.CheckSubmit = true
                this.confirm = false
                this.countArry = 0
                this.Slip.SumAmount = null
                this.Slip.date = null
                this.Slip.time = null
                this.Slip.listID = null
                this.Slip.count = null
                this.Slip.LottoHeadName = null
                if (response.success === true) {
                  this.showModal(response)
                  // this.SwalMes(
                  //   'success',
                  //   'ส่งโพยเรียบร้อย',
                  //   `ยอดแทงหวยทั้งหมด ${this.Commas(
                  //     Obj.SumAmount,
                  //   )} บาท ยอดคงเหลือ ${this.Commas(
                  //     response.WalletData.balance,
                  //   )} บาท`,
                  // )
                } else {
                  this.showAlert(response.Mes)
                }
              }
            } catch (err) {
              this.SwalMes('error', 'ส่งโพยไม่สำเร็จ', err.response.data.Mes)
              // console.log(err)
              this.CheckSubmit = true
            }
          }
        }
      })
    },
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://webapi.linelotto.vip/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowTitle() {
      const IndexHead = this.LottoList.findIndex(
        ({ value }) => value === this.Title.LottoHead,
      )
      if (this.Title.LottoSubHead) {
        const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === this.Title.LottoSubHead)
        this.Title.LottoName = this.LottoList[IndexHead].SubList[IndexSub].text
        this.Title.LottoDetail = this.LottoList[IndexHead].SubList[IndexSub].Data
      } else {
        this.Title.LottoName = this.LottoList[IndexHead].text
        this.Title.LottoDetail = this.LottoList[IndexHead].Data
      }
      if (this.Title.LottoDetail.u3 || this.Title.LottoDetail.d3 || this.Title.LottoDetail.t3) {
        this.ShowDigit = 3
      } else if (this.Title.LottoDetail.u2 || this.Title.LottoDetail.d2 || this.Title.LottoDetail.t2) {
        this.ShowDigit = 2
      } else if (this.Title.LottoDetail.u1 || this.Title.LottoDetail.d1) {
        this.ShowDigit = 1
      }
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://webapi.linelotto.vip/api/round/ShowByHeadAndSub',
          { params },
        )
        if (ResData.success) {
          // eslint-disable-next-line no-underscore-dangle
          this.Title.RoundID = ResData.mes._id
          this.Title.CloseBet = ResData.mes.StopBetTime
          this.Title.CloseDate = ResData.mes.CloseDate
          this.Title.CloseDateName = ResData.mes.CloseDateName
          this.Title.LimitU3 = ResData.mes.LimitU3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD3 = ResData.mes.LimitD3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT3 = ResData.mes.LimitT3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU2 = ResData.mes.LimitU2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD2 = ResData.mes.LimitD2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT2 = ResData.mes.LimitT2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU1 = ResData.mes.LimitU1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD1 = ResData.mes.LimitD1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.CloseBetU3 = ResData.mes.CloseBetU3.map(x => x.NumLot)
          this.Title.CloseBetD3 = ResData.mes.CloseBetD3.map(x => x.NumLot)
          this.Title.CloseBetT3 = ResData.mes.CloseBetT3.map(x => x.NumSort)
          this.Title.CloseBetU2 = ResData.mes.CloseBetU2.map(x => x.NumLot)
          this.Title.CloseBetD2 = ResData.mes.CloseBetD2.map(x => x.NumLot)
          this.Title.CloseBetT2 = ResData.mes.CloseBetT2.map(x => x.NumSort)
          this.Title.CloseBetU1 = ResData.mes.CloseBetU1.map(x => x.NumLot)
          this.Title.CloseBetD1 = ResData.mes.CloseBetD1.map(x => x.NumLot)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetMaxMin() {
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://webapi.linelotto.vip/api/rate/ShowByHeadAndSub',
          // 'https://webapi.linelotto.vip/api/round/ShowByHeadAndSub',
          { params },
        )
        if (ResData.success) {
          this.RateAll.Three.Up.Min = ResData.mes.MinU3
          this.RateAll.Three.Up.Max = ResData.mes.MaxU3
          this.RateAll.Three.Down.Min = ResData.mes.MinD3
          this.RateAll.Three.Down.Max = ResData.mes.MaxD3
          this.RateAll.Three.Tot.Min = ResData.mes.MinT3
          this.RateAll.Three.Tot.Max = ResData.mes.MaxT3
          this.RateAll.Two.Up.Min = ResData.mes.MinU2
          this.RateAll.Two.Up.Max = ResData.mes.MaxU2
          this.RateAll.Two.Down.Min = ResData.mes.MinD2
          this.RateAll.Two.Down.Max = ResData.mes.MaxD2
          this.RateAll.Two.Tot.Min = ResData.mes.MinT2
          this.RateAll.Two.Tot.Max = ResData.mes.MaxT2
          this.RateAll.One.Up.Min = ResData.mes.MinU1
          this.RateAll.One.Up.Max = ResData.mes.MaxU1
          this.RateAll.One.Down.Min = ResData.mes.MinD1
          this.RateAll.One.Down.Max = ResData.mes.MaxD1
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetRate(RoundID) {
      const params = {
        RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://webapi.linelotto.vip/api/ratebyround/showbetmore',
          { params },
        )
        if (ResData.success) {
          const Rate = ResData.mes.Data
          const ListBet = ResData.mes.LimitData
          this.RateAll.Three.Up.Rate = Rate.U3
          this.RateAll.Three.Up.BetLimit = Rate.BetLimitU3
          this.RateAll.Three.Up.BetList = ListBet.U3
          this.RateAll.Three.Down.Rate = Rate.D3
          this.RateAll.Three.Down.BetLimit = Rate.BetLimitD3
          this.RateAll.Three.Down.BetList = ListBet.D3
          this.RateAll.Three.Tot.Rate = Rate.T3
          this.RateAll.Three.Tot.BetLimit = Rate.BetLimitT3
          this.RateAll.Three.Tot.BetList = ListBet.T3
          this.RateAll.Two.Up.Rate = Rate.U2
          this.RateAll.Two.Up.BetLimit = Rate.BetLimitU2
          this.RateAll.Two.Up.BetList = ListBet.U2
          this.RateAll.Two.Down.Rate = Rate.D2
          this.RateAll.Two.Down.BetLimit = Rate.BetLimitD2
          this.RateAll.Two.Down.BetList = ListBet.D2
          this.RateAll.Two.Tot.Rate = Rate.T2
          this.RateAll.Two.Tot.BetLimit = Rate.BetLimitT2
          this.RateAll.Two.Tot.BetList = ListBet.T2
          this.RateAll.One.Up.Rate = Rate.U1
          this.RateAll.One.Up.BetLimit = Rate.BetLimitU1
          this.RateAll.One.Up.BetList = ListBet.U1
          this.RateAll.One.Down.Rate = Rate.D1
          this.RateAll.One.Down.BetLimit = Rate.BetLimitD1
          this.RateAll.One.Down.BetList = ListBet.D1
          // this.CloseBet.CloseU3.push(
          //   ...this.CalCloseBet(ListBet.U3, Rate.BetLimitU3),
          // )
          this.CloseBet.CloseU3 = this.CalCloseBet(
            ListBet.U3,
            this.Title.CloseBetU3,
            Rate.BetLimitU3,
          )
          this.CloseBet.CloseD3 = this.CalCloseBet(
            ListBet.D3,
            this.Title.CloseBetD3,
            Rate.BetLimitD3,
          )
          this.CloseBet.CloseT3 = this.CalCloseBet(
            ListBet.T3,
            this.Title.CloseBetT3,
            Rate.BetLimitT3,
          )
          this.CloseBet.CloseU2 = this.CalCloseBet(
            ListBet.U2,
            this.Title.CloseBetU2,
            Rate.BetLimitU2,
          )
          this.CloseBet.CloseD2 = this.CalCloseBet(
            ListBet.D2,
            this.Title.CloseBetD2,
            Rate.BetLimitD2,
          )
          this.CloseBet.CloseT2 = this.CalCloseBet(
            ListBet.T2,
            this.Title.CloseBetT2,
            Rate.BetLimitT2,
          )
          this.CloseBet.CloseU1 = this.CalCloseBet(
            ListBet.U1,
            this.Title.CloseBetU1,
            Rate.BetLimitU1,
          )
          this.CloseBet.CloseD1 = this.CalCloseBet(
            ListBet.D1,
            this.Title.CloseBetD1,
            Rate.BetLimitD1,
          )

          this.Title.LimitU3 = this.CalLimit(
            this.Title.LimitU3,
            this.CloseBet.CloseU3,
          )
          this.Title.LimitD3 = this.CalLimit(
            this.Title.LimitD3,
            this.CloseBet.CloseD3,
          )
          this.Title.LimitT3 = this.CalLimit(
            this.Title.LimitT3,
            this.CloseBet.CloseT3,
          )
          this.Title.LimitU2 = this.CalLimit(
            this.Title.LimitU2,
            this.CloseBet.CloseU2,
          )
          this.Title.LimitD2 = this.CalLimit(
            this.Title.LimitD2,
            this.CloseBet.CloseD2,
          )
          this.Title.LimitT2 = this.CalLimit(
            this.Title.LimitT2,
            this.CloseBet.CloseT2,
          )
          this.Title.LimitU1 = this.CalLimit(
            this.Title.LimitU1,
            this.CloseBet.CloseU1,
          )
          this.Title.LimitD1 = this.CalLimit(
            this.Title.LimitD1,
            this.CloseBet.CloseD1,
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    CompleteNumBet() {
      if (this.ShowDigit === 3) {
        this.PushDigit3()
      } else if (this.ShowDigit === 2) {
        this.PushDigit2()
      } else if (this.ShowDigit === 1) {
        this.PushDigit1()
      }
    },
    async PushDigit3() {
      const Swap = this.BetType.indexOf('กลับตัวเลข')
      const UP3 = this.BetType.indexOf('3ตัวบน')
      const DOWN3 = this.BetType.indexOf('3ตัวล่าง')
      const TOT = this.BetType.indexOf('3ตัวโต๊ด')
      // eslint-disable-next-line no-nested-ternary
      if (Swap >= 0) {
        if (UP3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวบน', 3, 'UP', this.AmountBet)
          })
        }
        if (DOWN3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวล่าง', 3, 'DOWN', this.AmountBet)
          })
        }
      } else if (TOT >= 0 || UP3 >= 0 || DOWN3 >= 0) {
        if (TOT >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวโต๊ด',
            3,
            'TOT',
            this.AmountBet,
          )
        }
        if (UP3 >= 0) {
          await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', this.AmountBet)
        }
        if (DOWN3 >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวล่าง',
            3,
            'DOWN',
            this.AmountBet,
          )
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit2() {
      const Up = this.BetType.indexOf('2ตัวบน') >= 0
      const Down = this.BetType.indexOf('2ตัวล่าง') >= 0
      const Tot = this.BetType.indexOf('2ตัวโต๊ด(บน)') >= 0
      if (this.BetType.indexOf('19ประตู') >= 0) {
        const Res = this.Swipe19(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหน้า') >= 0) {
        const Res = this.SwipeFront(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหลัง') >= 0) {
        const Res = this.SwipeBack(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('กลับตัวเลข') >= 0) {
        const Res = this.SwitchNum2(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else {
        if (Up) {
          await this.PushItems(this.NumBet, '2ตัวบน', 2, 'UP', this.AmountBet)
        }
        if (Down) {
          await this.PushItems(this.NumBet, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
        }
        if (Tot) {
          await this.PushItems(this.NumBet, '2ตัวโต๊ด(บน)', 2, 'TOT', this.AmountBet)
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit1() {
      const NumType = this.BetType.indexOf('วิ่งบน') >= 0 ? 'วิ่งบน' : 'วิ่งล่าง'
      const NumTypePrefix = this.BetType.indexOf('วิ่งบน') >= 0 ? 'UP' : 'DOWN'

      await this.PushItems(
        this.NumBet,
        NumType,
        1,
        NumTypePrefix,
        this.AmountBet,
      )
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushItems(NumBet, NumType, BetDigit, BetType, Price) {
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        const { Rate, RateLimit } = this.CalRate(NumBet, BetDigit, BetType)
        let NumSort = null
        let CheckDup = null
        if (BetType === 'TOT') {
          const NumArr = NumBet.split('')
          NumArr.sort()
          NumSort = NumArr.join('')
          CheckDup = this.items.findIndex(
            obj => obj.NumSort === NumSort
            && obj.bet_digit === BetDigit
            && obj.bet_type === BetType,
          )
        } else {
          CheckDup = this.items.findIndex(
            obj => obj.num_lot === NumBet
            && obj.bet_digit === BetDigit
            && obj.bet_type === BetType,
          )
        }

        if (CheckDup >= 0) {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price) + Number(this.items[CheckDup].price),
          )
          if (CheckLimit.status === true) {
            this.items[CheckDup].price = Number(Price) + Number(this.items[CheckDup].price)
            resolve()
          } else if (CheckLimit.amount <= 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        } else {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price),
          )
          if (CheckLimit.status === true) {
            if (RateLimit) {
              this.SwalMes('info', 'หมายเลขจ่ายตาม %', `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} บาท`)
            }
            const ObjItems = {
              num_lot: NumBet,
              num_type: NumType,
              bet_digit: BetDigit, // 1, 2 ,3
              bet_type: BetType, // TOT , UP , DOWN
              price: Price,
              round_id: this.Title.RoundID,
              LottoHead: this.Title.LottoHead,
              LottoSubHead: this.Title.LottoSubHead
                ? this.Title.LottoSubHead
                : null,
              win_rate: Rate,
              NumSort,
              RateLimit,
            }
            this.items.push(ObjItems)
            resolve()
          } else if (CheckLimit.amount === 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        }
      })
    },
    async PushHistory(Val) {
      this.countArry = Val.length
      const St = JSON.stringify(Val)
      this.Historyitems.push(JSON.parse(St))
    },
    CalCloseBet(Arr, CloseList, BetLimit) {
      let arrArr = Arr.filter(ele => ele.totalAmount >= BetLimit)
      // eslint-disable-next-line no-underscore-dangle
      arrArr = arrArr.map(x => x._id)
      if (CloseList.length > 0) {
        arrArr.push(...CloseList)
      }
      return arrArr.sort()
    },
    CalRate(NumBet, BetDigit, BetType) {
      let Rate = 0
      let IndexLimit = null
      let RateLimit = false
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Down.Rate
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Tot.Rate
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Tot.Rate
          }
        }
      }
      if (IndexLimit >= 0) {
        RateLimit = true
      }
      return { Rate, RateLimit }
    },
    CalLimit(Limit, CloseBet) {
      return Limit.filter(val => !CloseBet.includes(val.NumLot))
    },
    SelectDigit(Val) {
      this.NumBet = ''
      this.AmountBet = 1
      this.BetType = []
      this.CheckBetType = true
      this.maxlength = Val
      this.ShowDigit = Val
      this.BugOtp = false
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.BugOtp = true), 10)
    },
    ClearAfterBet() {
      this.NumBet = ''
      this.AmountBet = 1
      this.BugOtp = false
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.BugOtp = true), 10)
    },
    SelectBetType3(Val) {
      this.SelectUnDup(this.BetType, Val)
      if (Val === 'กลับตัวเลข') {
        this.CheckRemove(this.BetType, '3ตัวโต๊ด')
      }
      if (Val === '3ตัวโต๊ด') {
        this.CheckRemove(this.BetType, 'กลับตัวเลข')
      }
      this.CheckBetDisable(this.BetType, '3ตัวบน', '3ตัวโต๊ด', '3ตัวล่าง')
    },
    async SelectBetType2(Val) {
      // eslint-disable-next-line no-nested-ternary
      const NumType = this.BetType.indexOf('2ตัวบน') >= 0
        ? '2ตัวบน'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? '2ตัวล่าง'
          : '2ตัวโต๊ด(บน)'
      // eslint-disable-next-line no-nested-ternary
      const NumTypePrefix = this.BetType.indexOf('2ตัวบน') >= 0
        ? 'UP'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? 'DOWN'
          : 'TOT'
      if (Val === 'สองตัวคี่') {
        const Res = this.SwipeOdd()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวคู่') {
        const Res = this.SwipeEven()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวสูง') {
        const Res = this.SwipeHigh()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวต่ำ') {
        const Res = this.SwipeLow()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else {
        this.SelectUnDup(this.BetType, Val)
        if (Val === '2ตัวบน') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวล่าง')
          this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวล่าง') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวบน')
          // this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวโต๊ด(บน)') {
          this.maxlength = 2
          this.CheckRemove(this.BetType, '2ตัวบน')
          this.CheckRemove(this.BetType, '2ตัวล่าง')
        } else if (Val === '19ประตู') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหน้า') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหลัง') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'กลับตัวเลข') {
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        }
        this.CheckBetDisable(this.BetType, '2ตัวบน', '2ตัวล่าง', '2ตัวโต๊ด(บน)')
      }
    },
    SelectBetType1(Val) {
      this.SelectUnDup(this.BetType, Val)
      this.CheckBetDisable(this.BetType, 'วิ่งบน', 'วิ่งล่าง')
    },
    CheckRemove(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index >= 0) {
        Arr.splice(Index, 1)
      }
    },
    SelectUnDup(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index < 0) {
        Arr.push(Val)
        const { Min, Max } = this.CheckAmount(Val)
        if (Max !== 0) {
          this.MinAmount = Min
          this.MaxAmount = Max
        }
      } else {
        Arr.splice(Index, 1)
      }
    },
    CheckBetDisable(Arr, Val1, Val2, Val3 = null) {
      const Index1 = Arr.indexOf(Val1)
      const Index2 = Arr.indexOf(Val2)
      const Index3 = Arr.indexOf(Val3)
      if (Index1 >= 0 || Index2 >= 0 || Index3 >= 0) {
        this.CheckBetType = false
      } else {
        this.CheckBetType = true
      }
    },
    TestPrice(Price, Min, Max) {
      if (Price < Min || Price > Max) {
        return true
      }
      return false
    },
    CheckAmount(Val, Price, Index, NumBet = null) {
      const MinMax = { Min: 0, Max: 0 }
      let BetDigit = 3
      let BetType = 'UP'
      if (Val === '3ตัวบน') {
        MinMax.Min = this.RateAll.Three.Up.Min
        MinMax.Max = this.RateAll.Three.Up.Max
      } else if (Val === '3ตัวล่าง') {
        MinMax.Min = this.RateAll.Three.Down.Min
        MinMax.Max = this.RateAll.Three.Down.Max
        BetType = 'DOWN'
      } else if (Val === '3ตัวโต๊ด') {
        MinMax.Min = this.RateAll.Three.Tot.Min
        MinMax.Max = this.RateAll.Three.Tot.Max
        BetType = 'TOT'
      } else if (Val === '2ตัวบน') {
        MinMax.Min = this.RateAll.Two.Up.Min
        MinMax.Max = this.RateAll.Two.Up.Max
        BetDigit = 2
        BetType = 'UP'
      } else if (Val === '2ตัวล่าง') {
        MinMax.Min = this.RateAll.Two.Down.Min
        MinMax.Max = this.RateAll.Two.Down.Max
        BetDigit = 2
        BetType = 'DOWN'
      } else if (Val === '2ตัวโต๊ด(บน)') {
        MinMax.Min = this.RateAll.Two.Tot.Min
        MinMax.Max = this.RateAll.Two.Tot.Max
        BetDigit = 2
        BetType = 'TOT'
      } else if (Val === 'วิ่งบน') {
        MinMax.Min = this.RateAll.One.Up.Min
        MinMax.Max = this.RateAll.One.Up.Max
        BetDigit = 1
        BetType = 'UP'
      } else if (Val === 'วิ่งล่าง') {
        MinMax.Min = this.RateAll.One.Down.Min
        MinMax.Max = this.RateAll.One.Down.Max
        BetDigit = 1
        BetType = 'DOWN'
      }
      if (Price) {
        if (Price < MinMax.Min) {
          this.items[Index].price = MinMax.Min
        } else if (Price > MinMax.Max) {
          this.items[Index].price = MinMax.Max
        }
        const CheckLimit = this.CheckBetLimit(
          NumBet,
          BetDigit,
          BetType,
          Number(Price),
        )
        if (CheckLimit.status === false) {
          this.items[Index].price = CheckLimit.amount > 0 ? CheckLimit.amount : 0
        }
      }
      return MinMax
    },
    CheckBetLimit(NumBet, BetDigit, BetType, Price) {
      let TotalAmount = Price
      let SumAmount = 0
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT2.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT3.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      }
      return { status: true }
    },
    // 3Digit
    SwitchNum3(textnum) {
      const ReArr = []
      const num1 = textnum.substr(0, 1)
      const num2 = textnum.substr(1, 1)
      const num3 = textnum.substr(2, 1)
      if (num1 === num2 || num1 === num3 || num2 === num3) {
        if (num1 === num2) {
          ReArr.push(num1 + num1 + num3, num1 + num3 + num1, num3 + num1 + num1)
        } else if (num1 === num3) {
          ReArr.push(num1 + num2 + num1, num1 + num1 + num2, num2 + num1 + num1)
        } else {
          ReArr.push(num1 + num2 + num2, num2 + num2 + num1, num2 + num1 + num2)
        }
      } else {
        ReArr.push(
          num1 + num2 + num3,
          num1 + num3 + num2,
          num2 + num1 + num3,
          num2 + num3 + num1,
          num3 + num1 + num2,
          num3 + num2 + num1,
        )
      }
      return ReArr
    },
    // 2Digit
    SwitchNum2(textnum) {
      const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        ReArr.push(num1 + num2)
      } else {
        ReArr.push(num1 + num2, num2 + num1)
      }
      return ReArr
    },
    SwipeFront(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = String(Val) + i
        ReArr.push(R1)
      }
      return ReArr
    },
    SwipeBack(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      return ReArr
    },
    Swipe19(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        if (i === Number(Val)) {
          // eslint-disable-next-line no-plusplus
          i++
        }
        if (i !== 10) {
          const R2 = String(Val) + i
          ReArr.push(R2)
        }
      }
      return ReArr
    },
    SwipeLow() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 50; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeHigh() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 50; i < 100; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeOdd() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeEven() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2 !== 1) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    Undo() {
      if (this.Historyitems.length > 1) {
        this.Historyitems.pop()
        this.items = this.Historyitems[this.Historyitems.length - 1]
      } else {
        this.items = []
        this.Historyitems = []
      }
    },
    CalMaxLength() {
      const CheckLengthType = this.BetType.findIndex(
        obj => obj === '19ประตู' || obj === 'รูดหน้า' || obj === 'รูดหลัง',
      )
      if (CheckLengthType >= 0) {
        this.maxlength = 1
      } else {
        this.maxlength = this.ShowDigit
      }
      return this.maxlength
    },
    CalSumAmount() {
      const SumAmount = this.items.reduce(
        (acc, ele) => acc + Number(ele.price),
        0,
      )
      if (SumAmount > this.$store.state.appConfig.UserData.MainWallet) {
        if (this.CheckSubmit) {
          this.SwalMes(
            'error',
            'จำนวนเงินไม่ถูกต้อง !',
            'ยอดเงินของคุณไม่เพียงพอกรุณาลองใหม่อีกครั้งค่ะ',
          )
          this.CheckSubmit = !this.CheckSubmit
        }

        // this.items.splice(this.items.length - 1, 1)
      } else {
        this.CheckSubmit = true
      }
      if (this.AgentData.discount > 0) {
        this.SumAmount = SumAmount - ((this.AgentData.discount * SumAmount) / 100)
        return this.SumAmount
      }
      this.SumAmount = SumAmount
      return this.SumAmount
    },
    // eslint-disable-next-line consistent-return
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)

      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      clearInterval(this.Interval)
      this.$router.push({ name: 'lottery' })
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    SwalMes(Type, Title, Html) {
      this.$swal({
        icon: Type,
        title: Title,
        html: Html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    // Modal
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>
.color-orange {
    color: #22529A !important;
}
header {
  background: #fe7c86 !important;
}
.action-bar {
    background-color: #22529A !important;
}
.flex-fill {
  padding: 1px;
}
.view-rules {
    display: inline-flex;
    align-items: center;
    border: 0;
    text-align: center;
    background-color: #FE2F57;
    border-radius: 13px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 15px;

}
td {
  border: 1px solid #707070af;
  background: #fff;
  padding: 5px;
}
tr {
  margin: auto;
  width: 320px;
}
.form-css {
  border: none !important;
  border-radius: 10px !important;
}
.btns-bet-l {
    width: calc(100% - 110px);
}
.row-top-action {
    background-color: #22529A !important;
    background-image: url(/back_bg.svg) !important;
}
.row-bottom-action {
    background-color: #22529A !important;
    background-image: url(/back_bg.svg) !important;
}
@media only screen and (max-width: 768px){
  .row-bottom-action {
      width: 100%!important;
      left: 0;
      bottom: 0;
  }
}
@media (max-width: 768px){
.bet-action-bottom .bet-detail {
    width: 50%;
    border-bottom: 1px solid #7f0000;
}
}
.bet-action-bottom .bet-detail {
    min-width: 140px;
    padding: 10px 20px;
    border-right: 1px solid #7f0000;
}
.input-bottom-bet {
    width: calc(100% - 90px);
    height: 44px;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: 700;
    color: #FF6700;
    border: none;
    border-radius: 4px;
}
@media (max-width: 768px){
.bet-action-bottom .bet-button, .bet-action-bottom .bet-form {
    margin: 0 auto;
    width: 100%;
}
}
.bet-button {
    padding: 5px 20px 15px;
    width: 360px;
    display: flex;
}
.float-left {
    float: left!important;
}
a:focus, input {
    outline: 0;
}
.color-sub {
    color: #fff;
}
.color-white {
    color: #FFF;
}
.font-page-title {
    font-size: 20px;
}
.bet-list-item.yellow {
    color: #edad0c;
}
.bet-list-item.orange {
    color: #FF6700;
}
.bet-list-item.green {
    color: #2CA468;
}
.bet-list-item.yellow.selected, .bet-list-item.yellow.selected:hover {
    background-color: #FCC201;
    color: #FFF;
}
.bet-list-item {
    float: left;
    width: 100%;
    border-radius: 15px;
    background-color: #FFF;
    line-height: 20px;
    color: #818181;
    font-size: 12px;
    padding: 5px;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}
.font-page-title {
    font-size: 20px;
}
.box-bet-coin-white .box-footer, .font-sub-title {
    font-size: 12px;
}
.bet-sm-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    color: #818181;
    text-align: center;
    background-color: #FFF;
    display: block;
}
.btn1.active {
    background: #3b5898 !important;
    color: #fff !important;
  }
  .button-num-cal {
    width: 100%;
    background: linear-gradient(180deg,#FFF 0,#F1F1F1 100%);
    border: none;
    margin: 0 0 10px;
    font-size: 30px;
}
.button-num-cals {
    width: 100%;
    background: linear-gradient(180deg,#424242 0,#202020 100%);
    border: none;
    font-size: 16px;
    color: #fff;
    border-radius: 4px;
    margin: 0 0 10px;
    font-size: 30px;
}
.button-num-calss {
    width: 100%;
    background: linear-gradient(180deg,#424242 0,#202020 100%);
    border: none;
    font-size: 16px;
    border-radius: 4px;
    color: #fff;
    margin: 0 0 10px;
    font-size: 30px;
}
.button-action-cal, .button-num-cal {
    box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
    border-radius: 4px;
    text-align: center;
    height: 45px;
}

.clicked{
    background: #3b5898 !important;
    color: #fff !important;
}
.btns, .input-2-box .text-control {
    font-weight: 700;
    padding: 10px;
    line-height: 24px;
}
.bg-cradit {
  margin: auto;
  background-color: #fff;
  color: #000;
  width: 150px;
  font-size: 0.6rem;
  border-radius: 10px;
}

.condition-top {
  background-color: #d40000;
  border-radius: 10px 10px 0px 0px;
  padding: 5px;
  color: #fff;
}
.condition-body {
  background-color: #838383;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}
.btn-type3 {
  width: 100%;
  color: #d40000;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  /* border-radius: 25px; */
  font-weight: 700;
  border: 0px;
}
.btn-type2 {
  width: 100%;
  color: #d40000;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}
.btn-type1 {
  width: 100%;
  color: #d40000;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.active,
.btnp:focus {
  background-color: #FE2F57;
  border-bottom: 4px solid #ffffff;
  color: #FFFFFF;
}

.header-bg-content2 {
  background-color: #FE7C86;
  border-bottom: 2px solid #FE2F57;
}

.bg-side {
  background-color: #111;
  border-radius: 5px;
  padding: 2px;
  color: #fff;
}
.pdd {
  margin: auto;
  max-width: 540px;
}
hr {
  margin-top: -2px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(221, 221, 221, 0.75),
    rgba(0, 0, 0, 0)
  );
}
p {
  line-height: 0.7;
}

.bg-danger {
  background-color: #dc3545 !important;
}
.text-white {
  color: #fff !important;
}
@media screen and (max-width: 768px) {
  .btn-tanghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-tanghuay {
  line-height: 1;
}
</style>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import '@core/scss/vue/pages/page-auth.scss';
.form-control {
    padding: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0rem;
}
.style-1 {
  .otp-input-8-field input{
    border-radius: 5;
    border: 2px solid #c50000;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.col-4,
.col-sm-6 {
  padding: 0.18rem;
  max-width: 100%;
  flex-grow: 1;
}

</style>
